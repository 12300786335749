// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
// $FlowFixMe
import styles from './help-center-banner.module.scss';
import { USER_ROLES } from 'app/constants';
import type { Props } from './help-center-banner.component.types';

export class HelpCenterBannerComponent extends Component<Props> {
  render() {
    const { role, intl } = this.props;

    if (
      ![
        USER_ROLES.ROLE_VENUE_MANAGER,
        USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER,
        USER_ROLES.ROLE_VENUE_ACCOUNTANT,
        USER_ROLES.ROLE_VENUE_CASHIER,
      ].includes(role)
    ) {
      return null;
    }

    const helpCenterFrontPagelUrl = intl.formatMessage({ id: 'HELP_CENTER_BANNER.HELP_CENTER_FRONT_PAGE_URL' });
    const sartumWebsiteUrl = intl.formatMessage({ id: 'HELP_CENTER_BANNER.SMARTUM_WEBSITE_URL' });

    const materialBankUrl = intl.formatMessage({ id: 'HELP_CENTER_BANNER.MATERIAL_BANK_URL' });
    const settlementDetailsUrl = intl.formatMessage({ id: 'HELP_CENTER_BANNER.SETTLEMENT_DETAILS_URL' });
    const userRolesUrl = intl.formatMessage({ id: 'HELP_CENTER_BANNER.USER_ROLES_URL' });
    const companyInfoUrl = intl.formatMessage({ id: 'HELP_CENTER_BANNER.COMPANY_INFO_URL' });

    return (
      <div className={styles.banner}>
        <div>
          <FormattedMessage id="HELP_CENTER_BANNER.TITLE" tagName="h6" />
          <FormattedMessage
            id="HELP_CENTER_BANNER.DESCRIPTION1"
            tagName="p"
            values={{
              chatBotName: (
                <strong>
                  <FormattedMessage id="HELP_CENTER_BANNER.CHATBOT_NAME" />
                </strong>
              ),
              helpCenterFrontPagelLink: (
                <a href={helpCenterFrontPagelUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="HELP_CENTER_BANNER.HELP_CENTER_FRONT_PAGE" />
                </a>
              ),
              sartumWebsiteLink: (
                <a href={sartumWebsiteUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="HELP_CENTER_BANNER.SMARTUM_WEBSITE" />
                </a>
              ),
            }}
          />
          <FormattedMessage
            id="HELP_CENTER_BANNER.DESCRIPTION2"
            tagName="p"
            values={{
              materialBankLink: (
                <li>
                  <a href={materialBankUrl} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="HELP_CENTER_BANNER.MATERIAL_BANK" />
                  </a>
                </li>
              ),
              settlementDetailsLink: (
                <li>
                  <a href={settlementDetailsUrl} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="HELP_CENTER_BANNER.SETTLEMENT_DETAILS" />
                  </a>
                </li>
              ),
              userRolesLink: (
                <li>
                  <a href={userRolesUrl} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="HELP_CENTER_BANNER.USER_ROLES" />
                  </a>
                </li>
              ),
              companyInfoLink: (
                <li>
                  <a href={companyInfoUrl} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="HELP_CENTER_BANNER.COMPANY_INFO" />
                  </a>
                </li>
              ),
            }}
          />
          <FormattedMessage id="HELP_CENTER_BANNER.DESCRIPTION3" tagName="p" />
        </div>
      </div>
    );
  }
}
